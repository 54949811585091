<template>
  <div class="pdf-container" :loading="loading">
    <pdf :src="pdfUrl" v-for="item in numPages" :key="item" :page="item"></pdf>
  </div>
</template>

<script>
import pdf from 'vue-pdf'
export default {
  name: 'yblPdf',
  data () {
    return {
      loading: false,
      pdfUrl: '',
      numPages: 0
    }
  },
  created () {
    upsdk.pluginReady(function () {
      upsdk.setTitleStyle({
        navBackgroundColor: '0xffFFFFFF',
        appletStyle: 'black', //可选，black-黑色主题，white-白色主题
        backBtnVisible: '1', // 可选，左侧返回按钮是否显示。'0'不显示，'1'显示，不传或空则默认显示
        appletTitleBarVisible: '1',// 可选，标题栏是否显示。'0'不显示，'1'显示，默认显示
      })
    })
    let { url } = this.$route.query
    const _url = url.replace(/http(s)*:\/\/f4.youbaolian.cn/, '/pdf')
    this.src = pdf.createLoadingTask(_url)
    this.src.promise.then((pdf) => {
      this.numPages = pdf.numPages
    })
    this.pdfUrl = _url
  },
  mounted () {
  },
  components: {
    pdf
  },
  methods: {
  }
}
</script>

<style lang="less">
.pdf-container {
  position: relative;
  // height: 100vh;
  iframe {
    width: 100vw;
    min-height: 100vh;
  }
}
</style>
